/* eslint-disable camelcase */
import { DispatchPayloadType } from '../reducers'
import {
  SignUpInterface,
  SignInInterface,
  ForgotPasswordInterface,
  ConfirmForgotPasswordInterface,
} from './interfaces'
import { Auth } from 'aws-amplify'
import { SIGN_UP, SIGN_IN, FORGOT_PASSWORD, CONFIRM_FORGOT_PASSWORD } from '../constants'

export const signUp =
  ({ password, email, name, token, birthdate, zip_code, consent, policyCheck }: SignUpInterface) =>
  async (dispatch: (event: any) => void) => {
    try {
      dispatch({
        type: SIGN_UP.PENDING,
      })
      const attributes = {
        name,
        birthdate,
        'custom:agreeToRecieveEmails': String(consent),
        'custom:agreeWithPrivacy': String(policyCheck),
      } as Record<string, string | boolean>

      if (zip_code) {
        attributes['custom:zip_code'] = zip_code
      }

      await Auth.signUp({
        username: email,
        password,
        validationData: {
          recaptcha_token: token,
        },
        attributes,
      })
      dispatch({
        type: SIGN_UP.SUCCESS,
      })
    } catch (error: any) {
      dispatch({
        type: SIGN_UP.ERROR,
        payload: error.message,
      })
    }
  }

export const signUpReset = () => ({
  type: SIGN_UP.RESET,
})

export const isLoggedIn = () => async (dispatch: (event: DispatchPayloadType) => void) => {
  dispatch({
    type: SIGN_IN.SUCCESS,
  })
  localStorage.setItem('login', 'true')
}

export const signIn =
  ({ password, email }: SignInInterface) =>
  async (dispatch: (event: DispatchPayloadType) => void) => {
    try {
      dispatch({
        type: SIGN_IN.PENDING,
      })
      await Auth.signIn(email, password)
      dispatch({
        type: SIGN_IN.SUCCESS,
      })
      localStorage.setItem('login', 'true')
    } catch (error: any) {
      dispatch({
        type: SIGN_IN.ERROR,
        payload: error.message,
      })
    }
  }

export const logout = async (withoutRedirect?: boolean) => {
  localStorage.clear()
  if (!withoutRedirect) {
    window.location.href = '/'
  }
  Auth.signOut({
    global: true,
  })
}

export const signInReset = () => ({
  type: SIGN_IN.RESET,
})

export const forgotPassword =
  ({ email }: ForgotPasswordInterface) =>
  async (dispatch: (event: DispatchPayloadType) => void) => {
    try {
      dispatch({
        type: FORGOT_PASSWORD.PENDING,
      })
      await Auth.forgotPassword(email)
      dispatch({
        type: FORGOT_PASSWORD.SUCCESS,
      })
    } catch (error) {
      dispatch({
        type: FORGOT_PASSWORD.ERROR,
      })
    }
  }

export const forgotPasswordReset = () => ({
  type: FORGOT_PASSWORD.RESET,
})

export const confirmForgotPassword =
  ({ email, code, password }: ConfirmForgotPasswordInterface) =>
  async (dispatch: (event: DispatchPayloadType) => void) => {
    try {
      dispatch({
        type: CONFIRM_FORGOT_PASSWORD.PENDING,
      })
      await Auth.forgotPasswordSubmit(email, code, password)
      dispatch({
        type: CONFIRM_FORGOT_PASSWORD.SUCCESS,
      })
    } catch (error) {
      dispatch({
        type: CONFIRM_FORGOT_PASSWORD.ERROR,
      })
    }
  }

export const confirmForgotPasswordReset = () => ({
  type: CONFIRM_FORGOT_PASSWORD.RESET,
})
