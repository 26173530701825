import { FC, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'
import { GlobalRouteInterface } from './interface'
import { logout } from '../../store/actions/AuthActions'

const GlobalRoute: FC<GlobalRouteInterface> = ({ children, route }) => {
  const navigate = useNavigate()

  useEffect(() => {
    ;(async () => {
      try {
        await Auth.currentSession()
        if (route === 'auth') {
          navigate('/pick-em-game')
        }
      } catch {
        if (route === 'private') {
          navigate('/')
          logout(true)
        }
      }
    })()
  }, [route])

  return children
}
export default GlobalRoute
