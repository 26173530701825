import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { router } from './routes'
import moment from 'moment-timezone'
import GlobalRoute from './components/GlobalRoute'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
// import AgeGate from './components/AgeGate'

moment.tz.setDefault('America/Los_Angeles')

function App() {
  const [isMajor, setIsMajor] = useState(false)

  useEffect(() => {
    checkCookie()
  }, [])

  function checkCookie() {
    const validAge = getCookie('agegate')
    if (validAge === 'valid') {
      setIsMajor(true)
    }
  }

  function getCookie(cname: string) {
    const name = cname + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }

  const checkAgeGate = (val: boolean) => {
    setIsMajor(val)
  }

  const renderApp = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <BrowserRouter>
          <Routes>
            {router.map(({ path, element, route }) => (
              <Route
                key={path}
                path={path}
                element={route ? <GlobalRoute route={route}>{element}</GlobalRoute> : element}
              />
            ))}
          </Routes>
        </BrowserRouter>
      </LocalizationProvider>
    )
  }

  // const renderAgeGate = () => {
  //   return <AgeGate isValid={checkAgeGate} />
  // }

  return renderApp()
}

export default App
