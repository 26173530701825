export const SIGN_UP = {
  PENDING: 'SIGN_UP_PENDING',
  SUCCESS: 'SIGN_UP_SUCCESS',
  ERROR: 'SIGN_UP_ERROR',
  RESET: 'SIGN_UP_RESET',
}

export const SIGN_IN = {
  PENDING: 'SIGN_IN_PENDING',
  SUCCESS: 'SIGN_IN_SUCCESS',
  ERROR: 'SIGN_IN_ERROR',
  RESET: 'SIGN_IN_RESET',
}

export const FORGOT_PASSWORD = {
  PENDING: 'FORGOT_PASSWORD_PENDING',
  SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  ERROR: 'FORGOT_PASSWORD_ERROR',
  RESET: 'FORGOT_PASSWORD_RESET',
}

export const CONFIRM_FORGOT_PASSWORD = {
  PENDING: 'CONFIRM_FORGOT_PASSWORD_PENDING',
  SUCCESS: 'CONFIRM_FORGOT_PASSWORD_SUCCESS',
  ERROR: 'CONFIRM_FORGOT_PASSWORD_ERROR',
  RESET: 'CONFIRM_FORGOT_PASSWORD_RESET',
}

export const GET_EM_GAMES = {
  PENDING: 'GET_EM_GAMES_PENDING',
  SUCCESS: 'GET_EM_GAMES_SUCCESS',
  ERROR: 'GET_EM_GAMES_ERROR',
  RESET: 'GET_EM_GAMES_RESET',
}

export const SAVE_EM_GAMES = {
  PENDING: 'SAVE_EM_GAMES_PENDING',
  SUCCESS: 'SAVE_EM_GAMES_SUCCESS',
  ERROR: 'SAVE_EM_GAMES_ERROR',
  RESET: 'SAVE_EM_GAMES_RESET',
}

export const GET_USER_EM_GAMES = {
  PENDING: 'GET_USER_EM_GAMES_PENDING',
  SUCCESS: 'GET_USER_EM_GAMES_SUCCESS',
  ERROR: 'GET_USER_EM_GAMES_ERROR',
  RESET: 'GET_USER_EM_GAMES_RESET',
}

export const UPDATE_EM_GAMES = {
  PENDING: 'UPDATE_EM_GAMES_PENDING',
  SUCCESS: 'UPDATE_EM_GAMES_SUCCESS',
  ERROR: 'UPDATE_EM_GAMES_ERROR',
  RESET: 'UPDATE_EM_GAMES_RESET',
}
