import React from 'react'
// import ForgotPassword from './components/ForgotPassword'
// import ResetPassword from './components/ResetPassword'
import Wrapped from './components/Wrapped/index'
// import SignUp from './components/SignUp'
// import PickEmGame from './components/PickEmGame'
import PageNotFound from './components/404'
// import EmailVerification from './components/EmailVerification'

export const router = [
  {
    path: '*',
    element: <PageNotFound />,
  },
  // {
  //   path: '/forgot-password',
  //   element: <ForgotPassword />,
  //   route: 'auth',
  // },
  // {
  //   path: '/reset-password',
  //   element: <ResetPassword />,
  //   route: 'auth',
  // },
  {
    path: '/',
    element: <Wrapped />,
    route: 'auth',
  },
  // {
  //   path: '/sign-up',
  //   element: <SignUp />,
  //   route: 'auth',
  // },
  // {
  //   path: '/pick-em-game',
  //   element: <PickEmGame />,
  //   route: 'private',
  // },
  // {
  //   path: '/confirm-user',
  //   element: <EmailVerification />,
  //   route: 'auth',
  // },
]
