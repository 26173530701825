import React, { useEffect } from 'react'
import InnerPage from '../InnerPage'
import './styles.scss'
import { useNavigate } from 'react-router-dom'

const PageNotFound = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/')
  }, [])

  return (
    <InnerPage>
      <div className='page-not-found-container'>
        <div className='container'>
          <h2>404 Error, nothing found</h2>
        </div>
      </div>
    </InnerPage>
  )
}

export default PageNotFound
