import React from 'react'
import './styles.scss'

const Wrapped = () => {
  const handleClick = () => {
    window.location.href = 'https://fandom.budlight.com/'
  }
  return (
    <>
      <div className='wrapped-box'>
        <img className='' alt='' src={require('../../assets/images/wrapped/hero-wrapped.png')} />
        <div className='playing-box'>
          <img alt='' src={require('../../assets/images/wrapped/logo-wrapped-wight.svg').default} />
          <h1>Thanks for playing!</h1>
          <p>
            Our college game has wrapped up! But here&lsquo;s the deal – the fun isn&lsquo;t over!
            Join <br /> our NFL Easy Picks game for more chances to win epic prizes!
          </p>
          <button onClick={handleClick}>Join for free</button>
        </div>
      </div>
    </>
  )
}

export default Wrapped
