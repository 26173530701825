import { combineReducers } from 'redux'
import authReducer from './AuthReducers'
import emGamesReducer from './EmGamesReducers'

export type DispatchPayloadType = {
  type: string
  payload?: any
}

const rootReducer = combineReducers({
  authReducer,
  emGamesReducer,
})

export default rootReducer
