import { SIGN_UP, FORGOT_PASSWORD, CONFIRM_FORGOT_PASSWORD, SIGN_IN } from '../constants'
import { DispatchPayloadType } from './index'

import { AuthReducersInterface } from './interfaces'

const initialState: AuthReducersInterface = {
  signUpStatus: '',
  signInStatus: '',
  forgotPasswordStatus: '',
  confirmForgotPasswordStatus: '',
  errorMessage: '',
}

const authReducer = (
  state = initialState,
  { type, payload }: DispatchPayloadType,
): AuthReducersInterface => {
  switch (type) {
    case SIGN_UP.PENDING: {
      return {
        ...state,
        signUpStatus: 'pending',
      } as AuthReducersInterface
    }
    case SIGN_UP.SUCCESS: {
      return {
        ...state,
        signUpStatus: 'success',
      } as AuthReducersInterface
    }
    case SIGN_UP.ERROR: {
      return {
        ...state,
        signUpStatus: 'error',
        errorMessage: payload,
      } as AuthReducersInterface
    }
    case SIGN_UP.RESET: {
      return {
        ...state,
        signUpStatus: '',
      } as AuthReducersInterface
    }

    case SIGN_IN.PENDING: {
      return {
        ...state,
        signInStatus: 'pending',
      } as AuthReducersInterface
    }
    case SIGN_IN.SUCCESS: {
      return {
        ...state,
        signInStatus: 'success',
      } as AuthReducersInterface
    }
    case SIGN_IN.ERROR: {
      return {
        ...state,
        signInStatus: 'error',
        errorMessage: payload,
      } as AuthReducersInterface
    }
    case SIGN_IN.RESET: {
      return {
        ...state,
        signInStatus: '',
      } as AuthReducersInterface
    }

    case FORGOT_PASSWORD.PENDING: {
      return {
        ...state,
        forgotPasswordStatus: 'pending',
      } as AuthReducersInterface
    }
    case FORGOT_PASSWORD.SUCCESS: {
      return {
        ...state,
        forgotPasswordStatus: 'success',
      } as AuthReducersInterface
    }
    case FORGOT_PASSWORD.ERROR: {
      return {
        ...state,
        forgotPasswordStatus: 'error',
      } as AuthReducersInterface
    }
    case FORGOT_PASSWORD.RESET: {
      return {
        ...state,
        forgotPasswordStatus: '',
      } as AuthReducersInterface
    }

    case CONFIRM_FORGOT_PASSWORD.PENDING: {
      return {
        ...state,
        confirmForgotPasswordStatus: 'pending',
      } as AuthReducersInterface
    }
    case CONFIRM_FORGOT_PASSWORD.SUCCESS: {
      return {
        ...state,
        confirmForgotPasswordStatus: 'success',
      } as AuthReducersInterface
    }
    case CONFIRM_FORGOT_PASSWORD.ERROR: {
      return {
        ...state,
        confirmForgotPasswordStatus: 'error',
      } as AuthReducersInterface
    }
    case CONFIRM_FORGOT_PASSWORD.RESET: {
      return {
        ...state,
        confirmForgotPasswordStatus: '',
      } as AuthReducersInterface
    }

    default:
      return state
  }
}

export default authReducer
