import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { Amplify } from 'aws-amplify'
import store from './store'
import Auth from './utils/auth'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/styles/style.scss'

Amplify.configure(Auth)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
