import { GET_EM_GAMES, SAVE_EM_GAMES, GET_USER_EM_GAMES, UPDATE_EM_GAMES } from '../constants'
import { DispatchPayloadType } from './index'

import { GetEmReducersInterface } from './interfaces'

const initialState: GetEmReducersInterface = {
  getEmGamesStatus: '',
  getEmGamesData: null,

  saveEmGamesStatus: '',
  saveEmGamesData: null,

  getUserEmGamesStatus: '',
  getUserEmGamesData: null,

  updateEmGamesStatus: '',
}

const emGamesReducer = (
  state = initialState,
  { type, payload }: DispatchPayloadType,
): GetEmReducersInterface => {
  switch (type) {
    case GET_EM_GAMES.PENDING: {
      return {
        ...state,
        getEmGamesStatus: 'pending',
      } as GetEmReducersInterface
    }
    case GET_EM_GAMES.SUCCESS: {
      return {
        ...state,
        getEmGamesStatus: 'success',
        getEmGamesData: payload,
      } as GetEmReducersInterface
    }
    case GET_EM_GAMES.ERROR: {
      return {
        ...state,
        getEmGamesStatus: 'error',
        getEmGamesData: null,
      } as GetEmReducersInterface
    }
    case GET_EM_GAMES.RESET: {
      return {
        ...state,
        getEmGamesStatus: '',
        getEmGamesData: null,
      } as GetEmReducersInterface
    }

    case SAVE_EM_GAMES.PENDING: {
      return {
        ...state,
        saveEmGamesStatus: 'pending',
      } as GetEmReducersInterface
    }
    case SAVE_EM_GAMES.SUCCESS: {
      return {
        ...state,
        saveEmGamesStatus: 'success',
        saveEmGamesData: payload,
      } as GetEmReducersInterface
    }
    case SAVE_EM_GAMES.ERROR: {
      return {
        ...state,
        saveEmGamesStatus: 'error',
        saveEmGamesData: null,
      } as GetEmReducersInterface
    }
    case SAVE_EM_GAMES.RESET: {
      return {
        ...state,
        saveEmGamesStatus: '',
        saveEmGamesData: null,
      } as GetEmReducersInterface
    }

    case GET_USER_EM_GAMES.PENDING: {
      return {
        ...state,
        getUserEmGamesStatus: 'pending',
      } as GetEmReducersInterface
    }
    case GET_USER_EM_GAMES.SUCCESS: {
      return {
        ...state,
        getUserEmGamesStatus: 'success',
        getUserEmGamesData: payload,
      } as GetEmReducersInterface
    }
    case GET_USER_EM_GAMES.ERROR: {
      return {
        ...state,
        getUserEmGamesStatus: 'error',
        getUserEmGamesData: null,
      } as GetEmReducersInterface
    }
    case GET_USER_EM_GAMES.RESET: {
      return {
        ...state,
        getUserEmGamesStatus: '',
        getUserEmGamesData: null,
      } as GetEmReducersInterface
    }

    case UPDATE_EM_GAMES.PENDING: {
      return {
        ...state,
        updateEmGamesStatus: 'pending',
      } as GetEmReducersInterface
    }
    case UPDATE_EM_GAMES.SUCCESS: {
      return {
        ...state,
        updateEmGamesStatus: 'success',
      } as GetEmReducersInterface
    }
    case UPDATE_EM_GAMES.ERROR: {
      return {
        ...state,
        updateEmGamesStatus: 'error',
      } as GetEmReducersInterface
    }
    case UPDATE_EM_GAMES.RESET: {
      return {
        ...state,
        updateEmGamesStatus: '',
      } as GetEmReducersInterface
    }

    default:
      return state
  }
}

export default emGamesReducer
