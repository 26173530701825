import React from 'react'
import styles from './footer.module.scss'

const Footer = () => {
  return (
    <footer>
      <div className='container'>
        <div className={styles.footer_parent_box}>
          <div className={styles.logo_section}>
            <img
              className={styles.logo}
              src={require('../../../assets/images/footer/footer-logo.png')}
            />
            <div className={styles.logo_box}>
              <a href='https://www.facebook.com/BudLight' target='_blank' rel='noreferrer'>
                <img src={require('../../../assets/images/footer/Facebook.svg').default} />
              </a>
              <a href='https://twitter.com/budlight' target='_blank' rel='noreferrer'>
                <img src={require('../../../assets/images/footer/Twitter.svg').default} />
              </a>
              <a
                href='https://www.youtube.com/user/officialbudlight'
                target='_blank'
                rel='noreferrer'
              >
                <img src={require('../../../assets/images/footer/YouTube.svg').default} />
              </a>
              <a href='https://www.instagram.com/budlight/' target='_blank' rel='noreferrer'>
                <img src={require('../../../assets/images/footer/Instagram.svg').default} />
              </a>
              <a href='https://discord.com/invite/budlight' target='_blank' rel='noreferrer'>
                <img src={require('../../../assets/images/footer/Discord.svg').default} />
              </a>
            </div>
          </div>
          <div className={styles.footer_menu_box}>
            <div>
              <h6>ANHEUSER-BUSCH</h6>
              <ul>
                <li>
                  <a href='https://www.anheuser-busch.com/' target='_blank' rel='noreferrer'>
                    ABOUT ANHEUSER-BUSCH
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.anheuser-busch.com/careers/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    CAREERS
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.anheuser-busch.com/community/responsible-drinking/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    SOCIAL RESPONSIBILITY
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.anheuser-busch.com/newsroom/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    PRESS
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h6>SUPPORT</h6>
              <ul>
                <li>
                  <a href='https://www.budlight.com/contact-us' target='_blank' rel='noreferrer'>
                    CONTACT US
                  </a>
                </li>
                <li>
                  <a href='https://tapintoyourbeer.com/' target='_blank' rel='noreferrer'>
                    CONSUMER INFORMATION
                  </a>
                </li>
                <li>
                  <a href='https://www.budlight.com/accessibility' target='_blank' rel='noreferrer'>
                    ACCESSIBILITY STATEMENT
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h6 style={{ textTransform: 'uppercase' }}>Policies and Legal</h6>
              <ul>
                <li>
                  <a
                    target='_blank'
                    href='https://www.budlight.com/terms-and-conditions'
                    rel='noreferrer'
                    style={{ textTransform: 'uppercase' }}
                  >
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a
                    target='_blank'
                    href='https://www.budlight.com/privacy-policy/'
                    rel='noreferrer'
                    style={{ textTransform: 'uppercase' }}
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    target='_blank'
                    href='https://www.budlight.com/california-residents-privacy-rights'
                    rel='noreferrer'
                    style={{ textTransform: 'uppercase' }}
                  >
                    Do Not Sell My Personal <br></br>Information
                  </a>
                </li>
                <li>
                  <a
                    target='_blank'
                    href='https://www.anheuser-busch.com/contact-us/'
                    rel='noreferrer'
                    style={{ textTransform: 'uppercase' }}
                  >
                    Limit The Use Of My Sensitive<br></br>Personal Information
                  </a>
                </li>
                {/* <button>join newsletter</button> */}
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.line_box} />
        <div className={styles.enjoy_box}>
          <p>
            ENJOY RESPONSIBLY. DO NOT SHARE THIS CONTENT WITH THOSE UNDER 21. © 2023 ANHEUSER-BUSCH,
            BUD LIGHT® BEER, ST. LOUIS, MO
          </p>
          {/* <ul>
            <li><a  target='_blank' href='https://www.budlight.com/accessibility' rel="noreferrer">Terms <br></br> & Conditions</a></li>
            <li><a  target='_blank'  href="https://www.budlight.com/privacy-policy/" rel="noreferrer">Privacy <br></br> Policy</a></li>
            <li><a target='_blank' href="https://www.budlight.com/california-residents-privacy-rights" rel="noreferrer">Do Not Sell My Personal <br></br>Information</a></li>
            <li><a target='_blank' href="https://www.tapintoyourbeer.com/" rel="noreferrer">Limit The Use Of My Sensitive<br></br>Personal Information</a></li>
          </ul> */}
        </div>
        <div className={styles.enjoy_box}>
          <p>
            ANHEUSER-BUSCH IS COMMITTED TO IMPROVING THE ACCESSIBILITY OF OUR WEBSITES FOR ALL
            USERS,INCLUDING THOSE WITH DISABILITIES.{' '}
            <a href='https://www.budlight.com/accessibility' target='_blank' rel='noreferrer'>
              READ OUR ACCESSIBILITY STATEMENT HERE.
            </a>
          </p>
        </div>
        <div className={styles.enjoy_box}>
          <p>
            BUD LIGHT ® EASY PICKS CFB. No Purchase Necessary. Open to US residents 21+. Begins
            8/26/23 and ends 10/14/23. See Official Rules at budlight.com/easypicksCFBrules for
            entry, prizes and details. Message and data rates may apply. Void where prohibited.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
